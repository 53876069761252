<template>
  <div>
    <!-- 编辑记录-用户信息 -->
    <div class="content1">
      <div class="all">
        <div class="table" >
          <div style="display:flex;margin-top:20px">
            <el-button  class="SearchBtn" size="small" @click="backtrack">返回</el-button>
          </div>
          <!--数据表格-->
          <div>
            <el-form class="form_box">
            <div class="front_box">
              
              <div class="particulars">
                <span class="img"></span>
                <span class="titles">编辑前数据</span>
              </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">用户信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="用户名称：" prop="positionName" label-width='120px'>
                        {{ oldData.userName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="联系人：" prop="positionName" label-width='120px'>
                        {{ oldData.contact }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="电话：" prop="positionName" label-width='120px'>
                        {{ oldData.userPhone }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="身份证号：" prop="positionName" label-width='120px'>
                        {{ oldData.userIdnumber }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="营业执照号：" prop="positionName" label-width='120px'>
                        {{ oldData.businessLicenseNumber }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="用户分类：" prop="positionName" label-width='120px'>
                        {{ oldData.userTypeName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="片区：" prop="positionName" label-width='120px'>
                        {{ oldData.addrName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="地址：" prop="positionName" label-width='120px'>
                        {{ oldData.address }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="负责部门：" prop="positionName" label-width='120px'>
                        {{ oldData.deptName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="安检员：" prop="positionName" label-width='120px'>
                        {{ oldData.inspectorName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="启用状态：" prop="positionName" label-width='120px'>
                        <span v-if="oldData.status==1">已启用</span>
                        <span v-if="oldData.status==2">停用安检</span>
                        <span v-if="oldData.status==3">停用不安检</span>
                        <span v-if="oldData.status==4">已销户</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="front_box">
              
              <div class="particulars">
                <span class="img" style="border: 1px solid #E93838"></span>
                <span class="titles">编辑后数据</span>
              </div>
                <div class="box_info">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item >
                        <span slot="label"><span style="color: #303133;font-size: 16px;font-weight: 900;">用户信息</span></span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="用户名称：" prop="positionName" label-width='120px'>
                        <span :class="newData.userName==oldData.userName?'':'red'">{{ newData.userName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="联系人：" prop="positionName" label-width='120px'>
                        <span :class="newData.contact==oldData.contact?'':'red'">{{ newData.contact }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="电话：" prop="positionName" label-width='120px'>
                        <span :class="newData.userPhone==oldData.userPhone?'':'red'">{{ newData.userPhone }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="身份证号：" prop="positionName" label-width='120px'>
                        <span :class="newData.userIdnumber==oldData.userIdnumber?'':'red'">{{ newData.userIdnumber }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="营业执照号：" prop="positionName" label-width='120px'>
                        <span :class="newData.businessLicenseNumber==oldData.businessLicenseNumber?'':'red'">{{ newData.businessLicenseNumber }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="用户分类：" prop="positionName" label-width='120px'>
                        <span :class="newData.userTypeName==oldData.userTypeName?'':'red'">{{ newData.userTypeName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="片区：" prop="positionName" label-width='120px'>
                        <span :class="newData.addrName==oldData.addrName?'':'red'">{{ newData.addrName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="地址：" prop="positionName" label-width='120px'>
                        <span :class="newData.address==oldData.address?'':'red'">{{ newData.address }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="负责部门：" prop="positionName" label-width='120px'>
                        <span :class="newData.deptName==oldData.deptName?'':'red'">{{ newData.deptName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="安检员：" prop="positionName" label-width='120px'>
                        <span :class="newData.inspectorName==oldData.inspectorName?'':'red'">{{ newData.inspectorName }}</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="启用状态：" prop="positionName" label-width='120px'>
                        <div :class="newData.status==oldData.status?'':'red'">
                          <span v-if="newData.status==1">已启用</span>
                          <span v-if="newData.status==2">停用安检</span>
                          <span v-if="newData.status==3">停用不安检</span>
                          <span v-if="newData.status==4">已销户</span>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import {userUpdateDetails} from '../../../RequestPort/intranet/index'
export default {
  name: "lockout",
  data(){
    return{
      newData:{

      },
      oldData:{

      }
    }
  },
  mounted() {
    this.loadList()
  },
  methods:{
    /**
     * 返回
     */
    backtrack(){
      this.$router.back(-1)
    },
    /**
     * 获取列表
     */
    loadList(){
      userUpdateDetails({recordId:this.$route.query.id}).then(res=>{
        this.newData = res.data.newData
        this.oldData = res.data.oldData
      })
    }
  }
}
</script>

<style lang="less" scoped>
.red{
  color: red;
}
.SearchBtn{
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  overflow: auto;
  height: calc(100vh - 194px);
  background-color: #ffffff;
}
.particulars {
  display: flex;
  flex-direction: row;
  align-items:center;
  height: 40px;
  position: relative;
  .img {
    border: 1px solid #3f74d5;
    height: 16px;
    margin-right: 9px;
  }
  // 标题文字
  .titles{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight:900;
    color: #303133;
  }
}
.el-form-item{
  margin: 0;
  ::v-deep.el-form-item__label{
        text-align: left;
  }
}
.form_box{
  display: flex;
  flex-direction: row;
}
// 数据前
.front_box{
  width: 778px;
  margin: 20px 30px;
  .box_info{
    padding-left: 29px;
  }
}
</style>